/*! OneTrustMini banner - v0.1.4 */
function OptanonWrapper() {

	const gpcEnabled = navigator.globalPrivacyControl;	// Detect if the browser transmits a GPC (Global Privacy Control) signal
	const cookieBannerContainer = document.getElementById('onetrust-consent-sdk');	// Top level OneTrust container

	// Append the Cookie consent container to the DOM
	document.body.prepend(cookieBannerContainer);

	// Display appropriate text based on GPS signal
	if (gpcEnabled) {
		/*
			In the Banner and Privacy Preference Center are two versions of text:
			1. scenario – no GPS signal is detected: display text in the container with the "gpc-off" class
			2. scenario – GPS signal detected: display text in the container with the "gpc-on" class
		*/
		cookieBannerContainer.classList.toggle("gpc-detected");
	}


	//
	//	Privacy Preference Center (<div id="onetrust-pc-sdk">)
	//
	/*
		1. Expands all category descriptions
		2. Relocate "Reject all" button from bottom to the top
	*/
	function setupPrivacyPreferenceCenter() {
		// No previous interaction with Cookie Consent Banner
		if (!OneTrust.IsAlertBoxClosed()) {

			// Preference Center buttons
			const otPcExpandButton = document.querySelectorAll('.ot-accordion-layout button');	// Expand Category description button
			const otPcRejectAllButton = document.querySelectorAll('.ot-pc-footer .ot-pc-refuse-all-handler');	// "Reject all" button
			const otPcAcceptAllButton = document.getElementById('accept-recommended-btn-handler');	// "Accept all" button
	
			// 1. Expand all Categories description
			for (var i = 0; i < otPcExpandButton.length; i++) {
				otPcExpandButton[i].setAttribute('aria-expanded', 'true');
			}
	
			// 2. Relocate "Reject all" button from bottom to the top (after the "Accept all" button)
			if (otPcRejectAllButton.length > 0 && otPcAcceptAllButton) {
				otPcAcceptAllButton.parentNode.insertBefore(otPcRejectAllButton[0], otPcAcceptAllButton.nextSibling);
			}
		}
	}
	setupPrivacyPreferenceCenter();


	//
	//	GPC – Only for California
	//
	function oneTrustMiniBanner() {
		if(!gpcEnabled && !OneTrust.IsAlertBoxClosed() && document.getElementById('otCaliforniaTemplate__text')) {
			console.log('OneTrustMini Banner - v0.1.5');		// TODO: remove this before release
	
			var otMini;	// HTML template for the Cookie Consent Banner will be stored here
			const expandedBannerCssClass = 'js-expanded';
			const hiddenBannerCssClass = 'js-hidden';
	
			// Checks if the page is an Academy page (www.avast.com/c-*)
			const homepageAttr = document.body.getAttribute('data-homepage');
			const isAcademyPage = homepageAttr ? homepageAttr.includes('/c-academy') : false;	// Only Academy pages have <body data-homepage="/c-academy">
			
	
			//
			//	1. Get data from OneTrust Cookie consent banner (<div id="onetrust-banner-sdk">)
			//
			const otData = {
				text: document.getElementById('otCaliforniaTemplate__text').textContent, // get text "This site uses"
				cookiePolicyLink: {
					text: document.querySelector(".ot-cookie-policy-link").textContent, // text for the link – "Cookies"
					href: document.querySelector(".ot-cookie-policy-link").getAttribute("href"), // link href attribute value
					ariaLabel: document.querySelector(".ot-cookie-policy-link").getAttribute("aria-label") // link aria-label attribute value
				},
				buttons: {
					accept: OneTrust.GetDomainData().AlertAllowCookiesText, // text for button "Accept"
					reject: OneTrust.GetDomainData().BannerRejectAllButtonText // text for button "Reject"
				}
			};
			/*
			// Access and log data
			console.log(otData.text);                     // get text "This site uses"
			console.log(otData.cookiePolicyLink.text);    // text "Cookies"
			console.log(otData.cookiePolicyLink.href);    // href attribute value
			console.log(otData.cookiePolicyLink.ariaLabel); // aria-label attribute value
			console.log(otData.buttons.accept);           // button "Accept"
			console.log(otData.buttons.reject);           // button "Reject"
			*/


			//
			// 2. Create OneTrust Mini Banner
			//
			function createOneTrustMiniBanner() {
				//
				// 2.1 Create main container
				//
				otMini = document.createElement('div');
				otMini.id = 'oneTrustMini';
				otMini.tabIndex = 0;	// Make the Banner accessible via keyboard
				
				// Mobile – handle tap on the Cookie icon (Expand the banner)
				otMini.addEventListener('touchend', function (e) {
					// Check if the 'expandedBannerCssClass' is already applied
					if (!otMini.classList.contains(expandedBannerCssClass)) {
						e.preventDefault(); // Prevent default only if the banner is not expanded (without this, the 'Reject' button was activated when the banner expanded)
						otMini.classList.add(expandedBannerCssClass);	// Expand the banner
					}
				});

				//
				// 2.2 Create Cookie icon
				//
				const cookieIcon = document.createElement('div');
				cookieIcon.id = 'oneTrustMini__cookie-icon';
				otMini.appendChild(cookieIcon);
	
				//
				// 2.3 Create Content container
				//
				const contentContainer = document.createElement('div');
				contentContainer.id = 'oneTrustMini__content';
				otMini.appendChild(contentContainer);
	
				//
				// 2.3.1 Create Policy Text container
				//
				const policyText = document.createElement('div');
				policyText.id = 'oneTrustMini__text';
	
				// Create the span element for the text
				const span = document.createElement('span');
				span.textContent = otData.text + ' '; // text "This site uses" + add space character after the text
	
				// Create Cookies policy link
				const link = document.createElement('a');
				link.href = otData.cookiePolicyLink.href;
				link.target = '_blank';
				link.id = 'oneTrustMini__policy-link';
				link.setAttribute('aria-label', otData.cookiePolicyLink.ariaLabel);
				link.textContent = otData.cookiePolicyLink.text; // link text - "cookies"
	
				// Append content to the Text container
				policyText.appendChild(span);
				policyText.appendChild(link);
	
				//
				// 2.3.2 Create Buttons container
				//
				const buttonsDiv = document.createElement('div');
				buttonsDiv.id = 'oneTrustMini__buttons';
	
				// Create Accept button
				const acceptButton = document.createElement('button');
				acceptButton.id = 'oneTrustMini--accept';
				acceptButton.textContent = otData.buttons.accept;
	
				// Handle click on Accept button
				acceptButton.addEventListener('click', function(){
					OneTrust.AllowAll(); // Call OneTrust SDK function
					otMini.classList.add(hiddenBannerCssClass); // Hide banner
				});
	
				// Create Reject button
				const rejectButton = document.createElement('button');
				rejectButton.id = 'oneTrustMini--reject';
				rejectButton.textContent = otData.buttons.reject;
	
				// Handle click on Reject button
				rejectButton.addEventListener('click', function(){
					OneTrust.RejectAll(); // Call OneTrust SDK function
					otMini.classList.add(hiddenBannerCssClass); // Hide banner
				});
	
				// Append buttons to the Buttons container
				buttonsDiv.appendChild(acceptButton);
				buttonsDiv.appendChild(rejectButton);
	
				//
				// 2.3 Append content to the Content container
				//
				contentContainer.appendChild(policyText);
				contentContainer.appendChild(buttonsDiv);
	
				//
				// 2.4 Append the whole Banner to the body
				//
				document.body.appendChild(otMini);
			}
			createOneTrustMiniBanner();
	
	
			//
			//	3. Expand/Collapse OneTrust Mini banner on scroll
			//
			function toggleBannerOnScroll() {
				window.addEventListener('scroll', function () {
					// Get the scroll position
					const scrollPosition = window.scrollY || document.documentElement.scrollTop;
	
					// Expand/Collapse the Banner when scrolled more then 200px from the top
					if (scrollPosition > 200) {
						if (isAcademyPage) {
							// Collapse Banner on Academy pages
							otMini.classList.remove(expandedBannerCssClass);
						} else {
							// Expand Banner on all other pages
							otMini.classList.add(expandedBannerCssClass);
						}
					}
				});
			}
			toggleBannerOnScroll();


			//
			//	4. Academy pages
			//
			function academyPagesBanner() {
				if (isAcademyPage) {
					// Banner has different position and is open by default (and collapsed on scroll)
					otMini.setAttribute('data-placement', 'left');	// used in CSS for changing Banner's position
					otMini.classList.add(expandedBannerCssClass);	// expand the Banner


					// Academy pages have Sticky Bar. Move Cookie Consent Banner above the Sticky Bar
					function adjustCookieConsentBannerPositionOnAcademyPages() {
						const bodyClasses = document.body.className.split(' ');
					
						// Extract platform class from <body> (for example "os-android")
						function getPlatformClass(classes) {
							for (var i = 0; i < classes.length; i++) {
								if (classes[i].startsWith('os-')) {
									return classes[i];
								}
							}
							return null;
						}
					
						const platformClass = getPlatformClass(bodyClasses);

						//
						//	Get reference to Sticky bars
						//
						/*
							Academy pages are using 3 different sticky bars:
							1. mobileStickyBar – used on Article detail template (example: https://www.avast.com/c-spear-phishing)
							2. desktopStickyBar - used on Article detail template
							3. toolPageStickyBar - used on Tool page template (example: https://www.avast.com/c-virus-removal-tool)
						*/
						const mobileStickyBar = document.querySelector('.sticky-post-widget');
						// Get the Sticky bar currently in use (a bar exists for each platform; retrieve the appropriate one)
						const desktopStickyBar = platformClass ? document.querySelector('.os-detect.' + platformClass + ' .sidebar-widget-transformable') : null;
						const toolPageStickyBar = platformClass ? document.querySelector('.os-detect.' + platformClass + ' .sticky-widget') : null;

						// Adjust position of the Cookie Consent Banner
						function adjustCookieConsentBannerMargin(stickyBar, isVisible) {
							// when the Sticky Bar is visible, get its height and set it as margin to the Cookie Consent Banner.
							// when the Sticky Bar NOT visible, reset margin to 0px
							const height = isVisible ? stickyBar.offsetHeight : 0;
							otMini.style.marginBottom = height + 'px';
						}
					
						function checkMobileStickyBarVisibility() {
							// check if the stickyBar exists and it's not hidden via CSS
							const isVisible = mobileStickyBar && window.getComputedStyle(mobileStickyBar).display !== 'none';
							adjustCookieConsentBannerMargin(mobileStickyBar, isVisible);
						}
					
						function checkDesktopStickyBarVisibility() {
							if (!desktopStickyBar) return;	// check if the stickyBar exists
							const style = window.getComputedStyle(desktopStickyBar);
							// this unique combinations indicates that the stickyBar is sticked to the bottom of the screen and is visible
							const isVisible = style.display === 'flex' && style.position === 'fixed';
							adjustCookieConsentBannerMargin(desktopStickyBar, isVisible);
						}

						function checkToolPageStickyBarVisibility() {
							if (!toolPageStickyBar) return;	// check if the stickyBar exists
							const style = window.getComputedStyle(toolPageStickyBar);
							// this unique combinations indicates that the stickyBar is sticked to the bottom of the screen and is visible
							const isVisible = style.display === 'block' && style.bottom === '0px';
							adjustCookieConsentBannerMargin(toolPageStickyBar, isVisible);
						}

						// Only one Sticky Bar is visible at a time. Call the appropriate function.
						function checkStickyBarVisibility() {
							const viewportWidth = window.innerWidth;
					
							if (viewportWidth < 1056) {
								checkMobileStickyBarVisibility();
								checkToolPageStickyBarVisibility();
							} else {
								checkDesktopStickyBarVisibility();
							}
						}
					
						// Initial visibility check
						checkStickyBarVisibility();
					
						// Re-check visibility on scroll and window resize
						window.addEventListener('scroll', checkStickyBarVisibility);
						window.addEventListener('resize', checkStickyBarVisibility);
					}
					adjustCookieConsentBannerPositionOnAcademyPages();
				}
			}
			academyPagesBanner();
		}
	}
	oneTrustMiniBanner();
	


	//
	//	GPC – Rest of the GPC locales
	//
	var gpcOnController = document.querySelector(".gpc-on"), // This element is present only in GPC templates
		gpcOKButton = document.getElementById("cookieGPCControlButton"), // "OK" button (in fact, this is an <a id="cookieGPCControlButton"> element). It is stored in <p id="onetrust-policy-text"> (text and HTML is defined in OneTrust admin).
		buttonContainer = document.querySelector(".banner-actions-container") // Button container (for the "Reject all", "Manage...")

	// Used only by GPS templates (except the California Template)
	// - other templates don't contain gpcOnController
	// - California Template doesn't contain gpcOKButton
	if (gpcOnController && gpcOKButton) {
		// Relocate the "OK" button (move it to the buttonContainer)
		buttonContainer.appendChild(gpcOKButton);

		// GPC signal is ON
		if (gpcEnabled) {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.Close(); // This will "Confirm" default settings set by the GPS signal (user can predefine his default settings in his browser. GPS signal then "shares" this settings with the Cookie Consent).

				// Cookie consent was closed. Re-enable scroll on the page (used on V2 pages)
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
		// GPC signal is OFF (user's browser doesn't support GPS signal)
		else {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.AllowAll();	// Accept cookies and hides OneTrust banner and overlay

				// Cookie consent was closed. Re-enable scroll on the page (used on V2 pages)
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
	}

}
